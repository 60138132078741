import React from 'react'
import styled from 'styled-components'
import GlobalStyle from '../globalStyles'
import NavBar from './NavBar'

const Layout = ({ children, bgImage, activeTab }) => {

    const LayoutContainer = styled.div`
        /* :before{ */
            content: "";
            position: fixed;
            left: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            display: block;
            background-image: url(${bgImage});
            background-size: cover;
            background-position: center;
            background-attachment: fixed;
            /* -webkit-filter: blur(5px);
            -moz-filter: blur(5px);
            -o-filter: blur(5px);
            -ms-filter: blur(5px);
            filter: blur(2px); */
            // This is really cool but blur should maybe come with the image 
            box-shadow: 0 0 12.5rem rgba(0,0,0,0.9) inset;
            overflow: scroll;
        /* } */
        
        //height: 100%;
`
    // const pageStyles = {
    //     color: "#232129",
    //     fontFamily: "-apple-system, Roboto, sans-serif, serif",
    //     backgroundImage: `url(${HomeBG})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center top",
    //     height: '100%'

    //   }
    const productionContent = (
            <LayoutContainer>
                <NavBar activeTab={activeTab} />
                <ContentGrid>
                    <SideColumn></SideColumn>
                    <ContentColumn>
                        {children}
                    </ContentColumn>
                    <SideColumn></SideColumn>
                </ContentGrid>
                <GlobalStyle />
            </LayoutContainer>
    )
    
    console.log(process.env.NODE_ENV)
    console.log(process.env.GATSBY_IS_PRODUCTION_READY)

    const test = process.env.GATSBY_IS_PRODUCTION_READY;

    if(test == 'true'){
        return productionContent
    } else{
        return (<h1>Under construction</h1>)
    }

    // return process.env.GATSBY_IS_PRODUCTION_READY ?
    // productionContent :
    // <h1>Under construction</h1>
}

export default Layout


const ContentGrid = styled.div`
//scroll-padding-top: 8.125rem;
// TODO: add margin instead of padding to make scrollbar make sense
padding-top: 7.125rem;
padding-bottom: 4.125rem;
display: flex;
@media (max-width: 415px) {
    display: block;
    padding-bottom: 8.125rem;
}
`

const ContentColumn = styled.div`
    flex: 3;
`

const SideColumn = styled.div`
    flex: 0.5;

    // needs to be removed probably
    /* @media only screen and (max-width: 600px) {
        display:none;
    } */

    
`

