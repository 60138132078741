import React from 'react'
import styled from 'styled-components'

const StyledTextBox = ({title, content}) => {
  return (
    <StyledTextBoxContainer>
      <h3>{title}</h3>
      <br />
      <p>
        {content}
      </p>
        
    </StyledTextBoxContainer>
  )
}

export default StyledTextBox

const StyledTextBoxContainer = styled.div`
> p, h3{
  color: white;
}
h3{
  font-weight: bold;
}
    padding: 3.75rem;
    display: flex;
    flex-direction:column;
    justify-content: center;
    //height: 400px;
    //width: 800px;
    background: rgba(70, 67, 70, 0.6);
    border-radius: 0.625rem;
    
`