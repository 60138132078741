import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/Layout";
import StyledTextBox from '../components/StyledTextBox'
import ImageTextPair from "../components/ImageTextPair";
import { useStaticQuery, graphql } from "gatsby"
import BGHome from "../images/bg_pocetna.jpg"
import styled from "styled-components";


// const pageStyles = {
//   color: "#232129",
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
//   backgroundImage: `url(${HomeBG})`,
//   backgroundSize: "cover",
//   backgroundPosition: "center top",
//   height: '100%'

// }

const instructorFlag = false;


const IndexPage = () => {

  // const pageDataQuery = graphql`
  // query {
  //   contentfulInfoONama {
  //     infoONamaNaslov
  //     infoONamaTekst {
  //       infoONamaTekst
  //     }
  //     instruktor1ime
  //     instruktor1slika {
  //       url
  //     }
  //     instruktor1tekst {
  //       instruktor1tekst
  //     }
  //     instruktor2ime
  //     instruktoriNaslov
  //     instruktor2slika {
  //       url
  //     }
  //     instruktor2tekst {
  //       instruktor2tekst
  //     }
  //   }
  // }

  // `
  //const data = useStaticQuery(pageDataQuery);
  //console.log(JSON.stringify(data, null, 4));

  return (
    <Layout activeTab="Home" bgImage={BGHome}>
      <main>
        <br />
        {/* <StyledTextBox
          title={data.contentfulInfoONama.infoONamaNaslov}
          content={data.contentfulInfoONama.infoONamaTekst.infoONamaTekst} />
        <br /> */}
        <SimpleBox>
          <h1>SIGURNO RUKOVANJE VATRENIM ORUŽJEM</h1>
          <a style={{ textDecoration: 'none', color: 'inherit' }} href="/signup">
            <CustomButton> Pridruži nam se</CustomButton>
          </a>
        </SimpleBox>
        {/* {
          instructorFlag &&
          (
            <Instructors>
              <InstructorsHeader>Naši instruktori</InstructorsHeader>
              <ImageTextPair
                imgSrc={data.contentfulInfoONama.instruktor1slika.url}
                title={data.contentfulInfoONama.instruktor1ime}
                text={data.contentfulInfoONama.instruktor1tekst.instruktor1tekst} />
              <ImageTextPair
                imgSrc={data.contentfulInfoONama.instruktor2slika.url}
                title={data.contentfulInfoONama.instruktor2ime}
                text={data.contentfulInfoONama.instruktor2tekst.instruktor2tekst} />
            </Instructors>
          )
        } */}
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>

const CustomButton = styled.button`
    //margin-top: 1rem;
    max-width: 20rem;
    background-color: rgba(70, 67, 70, 0.6);
    box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.5);
    height: 3.75rem;
    width: 20rem;
    color: #ffffff;
    border: 1px solid #000000;
`

const SimpleBox = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  h1{
    /* Extra large devices (large laptops and desktops, 1200px and up) */

    @media only screen and (min-width: 1500px) {
      margin-top: 15rem;
      font-size: 8rem;
    }
    margin-top: 8rem;
    font-size: 5rem;
    //font-weight: bold;
    color: #ffffff;
    text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;
  }
`

const Instructors = styled.div`
  background: rgba(70, 67, 70, 0.6);
    border-radius: 0.625rem;
`

const InstructorsHeader = styled.h3`
  color: white;
  padding-top: 3.75rem;
  padding-left: 3.75rem;
`

