import React, { useEffect } from 'react'
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
//import "bootstrap/dist/js/bootstrap.min.js";

import Logo from '../images/logo_trimmed.png'

const NavBar = ({activeTab}) => {
  console.log(activeTab);

  let tabClass = "inactiveTab";

  useEffect(() => {
    if (activeTab == "Home"){
      document.querySelector("#tab-home").classList.add('activeTab');
    } else if (activeTab == "Gallery") {
      document.querySelector("#tab-gallery").classList.add('activeTab');
    } else if (activeTab == "Registration") {
      document.querySelector("#tab-registration").classList.add('activeTab');
    } else if (activeTab == "Weapons") {
      document.querySelector("#tab-weapons").classList.add('activeTab');
    }
  }, [])
  

  return (
    <div>

      <CustomNavbar id="navbar" className="navbar navbar-light fixed-top navbar-expand-lg">
        <div className="container-fluid navbar-inner">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <a href="/">
            <LogoNavbar src={Logo} />
          </a>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <NavItem className="nav-item">
                <a className={`nav-link ${tabClass}`} id="tab-home" aria-current="page" href="/">POČETNA</a>
              </NavItem>
              <NavItem className="nav-item">
                <a className="nav-link" id="tab-gallery" href="/gallery">GALERIJA</a>
              </NavItem>
              <NavItem className="nav-item">
                <a className="nav-link" id="tab-registration" href="/signup">PRIDRUŽI SE</a>
              </NavItem>
              <NavItem className="nav-item">
                <a className="nav-link" id="tab-weapons" href="/weapons">ORUŽJA</a>
              </NavItem>
            </ul>
          </div>
        </div>
      </CustomNavbar>
    </div>
  )
}

export default NavBar;

const CustomNavbar = styled.nav`
background-color: rgba(70, 67, 67, 0.98);

/* .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba%255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

> .navbar-toggler-icon{
  color: white;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba%255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
} */
`

const LogoNavbar = styled.img`
  height: 5rem;
  margin-right: 3.125rem;
  margin-left: 3.125rem;
`

const NavItem = styled.li`
  >a.activeTab {
    text-decoration: underline;
    text-underline-offset: 0.4rem;
  }

  @media only screen and (max-width: 600px) {
    a {
    font-size: 2rem;
    }
  }

  :hover{
    text-decoration: underline;
    text-decoration-color: white;
    text-underline-offset: 0.4rem;
  }
  margin-right: 3.125rem;
  > a{
  color: white !important;
  }
`

