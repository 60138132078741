import { createGlobalStyle } from 'styled-components';
import * as font from "./fonts"

const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'PROGRESS PERSONAL USE';
        src: url(${font.ProgressTTF}) format(truetype);
    }
    @font-face {
        font-family: 'PROGRESS ITALIC';
        src: url(${font.ProgressItalicTTF}) format(truetype);
    }

    // Scrollbar hidden
    // TODO: remove if problematic
    ::-webkit-scrollbar {
        display: none;
    }

    /* :root {
        --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba%255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    } */
    input, button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    *{
        font-family: "PROGRESS ITALIC";
    }

    html{
        font-size: 16px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        html{
            font-size: 10px;
        }

    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        html{
            font-size: 10px;
        }

    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        html{
            font-size: 12px;
        }

    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        html{
            font-size: 14px;
        }

    }


    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        html{
            font-size: 16px;
        }

    }

    @media only screen and (min-width: 1500px) {
        html{
            font-size: 20px;
        }

    }
`;

export default GlobalStyle;