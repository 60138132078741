import React from 'react'
import styled from 'styled-components'

const ImageTextPair = ({imgSrc, title, text}) => {
  return (
    <ImagePairContainer>
        <Image src={imgSrc}/>
        <TextContainer>
            <h3>{title}</h3>
            <br />
            <p>{text}</p>
            </TextContainer>
    </ImagePairContainer>
  )
}

export default ImageTextPair

const ImagePairContainer = styled.div`
    /* background: rgba(70, 67, 70, 0.6);
    border-radius: 0.625rem; */
    padding: 3.75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    //align-items: flex-start;
    //justify-content: space-between;
`

const Image = styled.img`
    min-width: 25rem;
    max-height: 25rem;
    //max-width: 50rem;
    flex:1;
    //margin-right: 20px;
    border-radius: 0.5rem;
    object-fit: cover;
`

const TextContainer = styled.div`
    h3{
        font-weight: bold;
    }
    flex: 1;
    color: #ffffff;
    min-width: 3rem;
`